export const Map = () => {
    return (
        <div className="w-full flex flex-col justify-center">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10122.118570778295!2d5.4232463!3d50.635854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0fc2ac733ac91%3A0x95a7ccaaaa9dbf9d!2sAviapartner%20Cargo%20Liege%2C%20Rue%20St%20Exup%C3%A9ry%2022%2C%204460%20Gr%C3%A2ce-Hollogne!5e0!3m2!1sen!2sbe!4v1718557165659!5m2!1sen!2sbe"
                title="Google Map Location"
                width="100%"
                height="550"
                allowFullScreen=""
                loading="eager"
                referrerPolicy={"no-referrer-when-downgrade"}
            ></iframe>
        </div>
    );
};