import { motion } from "framer-motion"
import HeroImage from "../assets/HeroImage.png"

export const Hero = () => {
    return (
        <>
            <div className="flex flex-col">
                <div className="relative w-full h-[1024px]">
                    <img className="object-cover w-full h-full" src={HeroImage} alt="" />

                    <div className="absolute inset-0 flex justify-center items-center text-center">
                        <motion.div
                            className=""
                            initial={{ opacity: 1, y: 150 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: { delay: 0.2, duration: 0.9 },
                            }}
                            viewport={{ once: true, amount: 0 }}>
                            <h1 className='text-4xl text-[#FFC400] font-bold text-center max-sm:text-3xl drop-shadow-[0_15px_10px_rgba(0,0,0,1)] '>EGK-AIRCARGO SERVICES</h1>

                            <div className="bg-white px-2 py-2 border-2 rounded">
                                <span className='text-3xl font-bold text-center max-sm:text-xl'>Time is money. We save you both</span>
                            </div>
                        </motion.div>
                    </div >
                </div >
            </div >
        </>
    )
}