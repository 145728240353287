import React from 'react'

export const Services = () => {
    return (
        <div className='flex flex-col justify-center items-center bg-gray-100 gap-4 p-4 border-2 rounded'>
            <div className='flex justify-center items-left flex-col'>
                <h2 className='text-4xl font-bold'>OUR SERVICES</h2>
            </div>

            <div className='flex justify-center items-center flex-col gap-4'>
                <div className='w-full h-1 bg-gray-400'></div>
                <div className='flex flex-col'>
                    <h2 className='text-2xl font-bold'>Customs declaration</h2>
                    <p>We offer a professional customs clearance and representation service to traders exporting goods from Belgium. We provide an executive document that shows the lists and detailed description of goods bound for export.  Due to our customs declaration, the customs authorities wil control what types of goods or items can be exported.</p>
                </div>

                <div className='w-full h-1 bg-gray-400'></div>

                <div className='flex flex-col'>
                    <h2 className='text-2xl font-bold'>Freight Forwarding</h2>
                    <p>EGK cargo service provides the most trustworthy route to ensure its costumers goods or documentation to its final destination.  Our company organizes shipments for you or your corporation to get your goods from your  manufacturer or producer to your desired market, customer or final point of distribution.</p>
                </div>

                <div className='w-full h-1 bg-gray-400'></div>

                <div className='flex flex-col'>
                    <h2 className='text-2xl font-bold'>Air Freight</h2>
                    <p>Our agency provides the most quality services due to working with prestigious airlines like Ethiopian Airlines. We offer a range of secure and highly flexible air shipping service. This allows us to adjust our services to our customers desired requirements.</p>
                </div>

                <div className='w-full h-1 bg-gray-400'></div>

                <div className='flex flex-col'>
                    <h2 className='text-2xl font-bold'>Sea Freight</h2>
                    <p>With our centralized location in Europe, we have the advantage to reach numerous  destinations effortlessly. With our expertise in Europe and Africa, we offer the best way of transportation for each customer depending on individual desires. Our goal is always to deliver cargos to ultimate destination on timely basis.</p>
                </div>
                <div className='w-full h-1 bg-gray-400'></div>
            </div>
        </div >
    )
}
