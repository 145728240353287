import { Hero, Carousel, LeftToRightTextAnimation, Map, ContactDetails } from "../components"
import { AboutUs, Services } from "../pages"
import { Helmet } from 'react-helmet-async'
import { motion } from "framer-motion"

export const Home = () => {
    return (
        <>
            <Helmet>
                <title>HOME | EGK Aircargo</title>
                <meta name="keywords" content="Air cargo services, Export goods from Belgium, Ethiopian Airlines, EGK Air Cargo, Save time and money, Customs declaration, Reach numerous destinations, Secure air shipping, Trustworthy route, Time-critical transports, Logistical challenge, Logistical, Timely delivery, air cargo, 22 Saint-Exupéry, 4460 Grâce-Hollogne, Liège, BELGIUM" />
                <meta name="description" content="EGK Air Cargo: Established in 2020, offering flexible air and sea freight services with a focus on Europe and Africa. Located in Liège, Belgium." />
                <link rel="canonical" href="https://www.egk-aircargo-services.com/" />
            </Helmet>

            <main className="bg-[#fffefd] flex justify-center flex-col items-center gap-2">
                <section className="relative">
                    <Hero />
                </section>

                <div className="flex lg:max-w-screen-2xl justify-center items-center flex-col gap-4">
                    <Services />
                    <LeftToRightTextAnimation text="Some products that we have transported" />
                    <Carousel />
                    <AboutUs />
                </div >

                <section className="w-full lg:max-w-screen-2xl">
                    <main className="bg-[#fffefd] flex flex-col w-full">
                        <motion.div
                            className={`text-white`}
                            initial={{ opacity: 1, y: 50 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: { delay: 0, duration: 0.8 },
                            }}
                            viewport={{ once: false, amount: 0 }}>
                            <ContactDetails />
                        </motion.div>

                        <Map />

                    </main>
                </section>

            </main >
        </>
    )
}