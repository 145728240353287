import { TfiEmail } from "react-icons/tfi";
import { IoCallSharp } from "react-icons/io5";
import { FaMapLocationDot } from "react-icons/fa6";

export const ContactDetails = () => {
    return (
        <div className="flex flex-col items-center justify-center text-gray-700 bg-card items-center shadow-xl shadow-right lg:p-4 my-2">
            <div className="flex flex-col items-center">
                <h1 className="text-2xl text-black font-bold">OUR CONTACT DETAILS</h1>
                <div className="mb-4 h-[2px] w-[280px] bg-[#bb9f82]" />
            </div>

            <div className={`flex lg:flex-row items-center gap-12 justify-between text-left max-lg:flex-col`}>
                <span className="flex items-center gap-2 hover:cursor-pointer my-2">
                    <TfiEmail size="30px" />
                    <div className="flex flex-col">
                        <a
                            className="hover:underline"
                            href={`mailto:egk@egk-acs.com`}
                        >
                            egk@egk-acs.com
                        </a>
                        <a
                            className="hover:underline"
                            href={`mailto:egk.acs@gmail.com`}
                        >
                            egk.acs@gmail.com
                        </a>
                    </div>
                </span>

                <div className="flex gap-2 ">
                    <IoCallSharp size="33px" />
                    <div className="flex flex-col">
                        <a className="hover:underline" href="tel:+32 483 44 44 16">+32 483 44 44 16</a>
                        <a className="hover:underline" href="tel:+33 616 70 01 60">+33 616 70 01 60</a>
                    </div>
                </div>

                <a
                    href="https://maps.app.goo.gl/PV318PBWWSMbApPh8"
                    className="hover:underline hover:cursor-pointer"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span className="flex gap-2 justify-center items-center text-center mx-4">
                        <FaMapLocationDot size="30px" />
                        22 Saint-Exupéry, 4460 Grâce-Hollogne, Liège, BELGIUM
                    </span>
                </a>
            </div>
        </div>
    );
};