import { motion, useScroll } from "framer-motion"
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const ScrollProgress = () => {
    const { scrollYProgress } = useScroll();
    const location = useLocation();

    useEffect(() => {
        // Reset scrollYProgress when the route changes
        scrollYProgress.set(0);
    }, [location, scrollYProgress]);

    return (
        <motion.div
            className="progress-bar z-40 bg-rose-500"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                height: "7px",
                transformOrigin: "0%",
                scaleX: scrollYProgress,
            }}
        />
    )
}