import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Contact, PageNotFound } from "../pages"

const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default AllRoutes
