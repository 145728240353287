import { motion } from 'framer-motion'
import React from 'react'

export const LeftToRightTextAnimation = ({ text }) => {
    return (
        <motion.div
            className="flex flex-col w-full h-full items-center justify-center bg-card p-4 border-2 rounded"
            initial={{ opacity: 1, x: -150 }}
            whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.1, duration: 0.9 },
            }}
            viewport={{ once: false, amount: 0 }}>
            <p className="text-2xl font-bold mx-4 text-center">{text}</p>
        </motion.div>
    )
}
