import React from 'react'

export const AboutUs = () => {
    return (
        <div className='flex flex-col justify-center items-center bg-gray-100 gap-4 p-4 border-2 rounded'>
            <div className='flex lg:w-[1024px] justify-center items-center flex-col'>
                <h2 className='text-4xl font-bold'>ABOUT US</h2>
            </div>

            <div className='flex justify-center items-center flex-col gap-4'>
                <div className='w-full h-1 bg-gray-400'></div>
                <div className='flex flex-col'>
                    <h2 className='text-2xl font-bold'>Who are we?</h2>
                    <p>EGK air cargo was in the making for five years before it was ultimately established in 2020. Located in the heart of Europe, we are easily accessible to reach our customers. Our team is availble to create the most flexible solutions for your time-critical transports.  Our experienced teams are ready to solve any logistical challenge quickly, efficiently and professionally. EGK air cargo services helps you to find the optimal balance between speed and cost.</p>
                </div>
                <div className='w-full h-1 bg-gray-400'></div>
                <div className='flex flex-col'>
                    <h2 className='text-2xl font-bold'>Our mission and vision</h2>
                    <p>The mission of EGK cargo services is dedicated to saving both time and money of its cutomsers. We achieve this by processing your orders immediately and offering precisely defined transit times to help you meet the tightest of deadlines. We take a great deal into helping innovate and improve transportation between Europe and Africa.</p>
                </div>
                <div className='w-full h-1 bg-gray-400'></div>
            </div>
        </div >
    )
}