import { Map, ContactDetails } from "../components";
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet-async'

export const Contact = ({ homePage }) => {
    return (
        <>
            <Helmet>
                <title>CONTACT | EGK Aircargo</title>
                <meta name="keywords" content="Air cargo services, Export goods from Belgium, Ethiopian Airlines, EGK Air Cargo, Save time and money, Customs declaration, Reach numerous destinations, Secure air shipping, Trustworthy route, Time-critical transports, Logistical challenge, Logistical, Timely delivery, air cargo, 22 Saint-Exupéry, 4460 Grâce-Hollogne, Liège, BELGIUM" />
                <meta name="description" content="EGK Air Cargo: Established in 2020, offering flexible air and sea freight services with a focus on Europe and Africa. Located in Liège, Belgium." />
                <link rel="canonical" href="https://www.egk-aircargo-services.com/contact" />
            </Helmet>

            <main className="bg-[#fffefd] flex flex-col w-full">
                <Map />
                <motion.div
                    className={`text-white ${homePage ? "order-first" : ""}`}
                    initial={{ opacity: 1, y: 50 }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { delay: 0, duration: 0.8 },
                    }}
                    viewport={{ once: false, amount: 0 }}>
                    <ContactDetails />
                </motion.div>
            </main>
        </>
    );
};