import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";

export const getCarouselImage = createAsyncThunk(
    "homePage/getCarouselImage",
    async () => {
        const { data } = await axios.get("https://www.api.egk-aircargo-services.com/imageHandler/images/")
        return data;
    }
)


const initialState = {
    isLoading: false,
    isError: false,
    error: "",
    success: false,
    carouselImagesData: [],
};

const carouselImageSlice = createSlice({
    name: "homePage",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCarouselImage.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(getCarouselImage.fulfilled, (state, action) => {
            state.isLoading = false;
            state.carouselImagesData = action.payload;
            state.success = true;
        })

        builder.addCase(getCarouselImage.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
            state.error = "Request failed with status code 404!";
        })
    }
})

export default carouselImageSlice.reducer