import { useState, useEffect } from "react"
import { getCarouselImage } from '../reducers/carouselImagesSlice';
import { useDispatch, useSelector } from "react-redux";
import { PiSpinnerThin } from "react-icons/pi";

export const Carousel = () => {

    const { isLoading, isError, error, success, carouselImagesData } = useSelector(
        (state) => state.homePage
    )

    const dispatch = useDispatch();

    useEffect(() => {
        if (carouselImagesData.length === 0) {
            dispatch(getCarouselImage());
        }
    }, [dispatch, carouselImagesData]);


    const [currentCarIndex, setCurrentCarIndex] = useState(0);
    const nextAction = () => {
        setCurrentCarIndex((currentCarIndex + 1) % carouselImagesData.length)
    }

    const previousAction = () => {
        setCurrentCarIndex((currentCarIndex - 1 + carouselImagesData.length) % carouselImagesData.length)
    }

    const goToSlide = (index) => {
        setCurrentCarIndex(index);
    };

    return (
        <div id="indicators-carousel" className="relative w-full" data-carousel="static">
            {/* Carousel wrapper */}

            {
                isLoading ? (
                    <div className="flex justify-center items-center">
                        <PiSpinnerThin className="animate-spin" size="200px" />
                    </div>
                ) : isError ? (
                    <div className="flex justify-center flex-col items-center">
                        <p className="text-2xl mt-4">{error}</p>
                        <p className="text-2xl mt-4">Internet could be slow, please refresh the page again</p>
                    </div>
                ) : (
                    success && carouselImagesData.length > 0 &&
                    <>
                        <div className="relative h-56 overflow-hidden md:h-[900px]">
                            <div className="duration-700 ease-in-out" data-carousel-item>
                                <img src={`https://www.api.egk-aircargo-services.com${carouselImagesData[currentCarIndex].image}`} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                            </div>
                        </div>

                        <div className="absolute z-30 flex -translate-x-1/2 space-x-3 rtl:space-x-reverse bottom-5 left-1/2">
                            {carouselImagesData.map((_, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className={`max-sm:w-3 max-sm:h-3 w-5 h-5 rounded-full ${index === currentCarIndex ? 'bg-blue-500 w-4 border-2' : 'bg-gray-300'} hover:bg-blue-500 hover:w-5 hover:max-sm:w-3`}
                                    aria-current={index === currentCarIndex}
                                    aria-label={`Slide ${index + 1}`}
                                    data-carousel-slide-to={index}
                                    onClick={() => goToSlide(index)}
                                ></button>
                            ))}
                        </div>

                        {/* Slider controls */}
                        <button onClick={() => { previousAction() }} type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                            <span className="inline-flex items-center justify-center w-10 h-10 bg-rose-200/30  group-hover:bg-white/50  group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                                <svg className="w-4 h-4 text-white  rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                </svg>
                                <span className="sr-only">Previous</span>
                            </span>
                        </button>

                        <button onClick={() => { nextAction() }} type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                            <span className="inline-flex items-center justify-center w-10 h-10 bg-white/30  group-hover:bg-white/50 d group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                                <svg className="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                </svg>
                                <span className="sr-only">Next</span>
                            </span>
                        </button>
                    </>
                )
            }
        </div>
    )
}