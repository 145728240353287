import { Link } from "react-router-dom";
import { TfiEmail } from "react-icons/tfi";
import { IoCallSharp } from "react-icons/io5";
import { FaMapLocationDot } from "react-icons/fa6";

export const Footer = () => {
    return (
        <footer className="bg-footerBackground overflow-hidden px-4">
            <div className="mx-auto max-w-screen-xl py-4 lg:py-5">
                <div className="flex justify-center gap-36 max-sm:flex-col max-sm:gap-12">
                    <div className="max-md:text-sm">
                        <h2 className="underline mb-4 text-md font-semibold text-gray-900 uppercase select-none">
                            Links
                        </h2>
                        <ul>
                            <li className="mb-2">
                                <Link to="/" className="hover:underline">
                                    EGK
                                </Link>
                            </li>

                            <li>
                                <Link to="/contact" className="hover:underline">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="justify-self-center max-md:text-sm max-sm:mr-14 group relative">
                        <h2 className="underline mb-4 text-mg font-semibold text-gray-900 uppercase select-none">
                            Contact Us
                        </h2>

                        <span className="flex items-center gap-2 hover:cursor-pointer my-2">
                            <TfiEmail size="30px" />
                            <div className="flex flex-col">
                                <a className="hover:underline" href={`mailto:egk@egk-acs.com`} >
                                    egk@egk-acs.com
                                </a>
                                <a className="hover:underline" href={`mailto:egk.acs@gmail.com`}>
                                    egk.acs@gmail.com
                                </a>
                            </div>
                        </span>
                        <div className="flex gap-2">
                            <IoCallSharp size="33px" />
                            <div className="flex flex-col">
                                <a className="hover:underline" href="tel:+32 483 44 44 16">+32 483 44 44 16</a>
                                <a className="hover:underline" href="tel:+33 616 70 01 60">+33 616 70 01 60</a>
                            </div>
                        </div>

                        <a
                            href="https://maps.app.goo.gl/PV318PBWWSMbApPh8"
                            className="hover:underline hover:cursor-pointer"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="flex gap-2">
                                <FaMapLocationDot size="30px" />
                                22 Saint-Exupéry, 4460 Grâce-Hollogne, Liège, BELGIUM
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};